// Changes the download button link and icon based on the user's platform.
import usePlatformDetector from "@/hooks/usePlatformDetector";
import { Entry } from "contentful";
import { useRef } from "react";
import Button from "../atoms/button";
import Image from "next/image";
import { AlcatrazLocales } from "@/config/supported-countries";
import { useRouter } from "next/router";
import { getRouteDetails } from "@/utils/helpers";

const downloadMT4Map = new Map<AlcatrazLocales, string>([
  ["en", "Download MT4"],
  ["ar", "تنزيل MT4"],
  ["bn", "MT4 ডাউনলোড করুন"],
  ["es", "Descargar MT4"],
  ["fr", "Télécharger MT4"],
  ["hi", "MT4 डाउनलोड करें"],
  ["id", "Unduh MT4"],
  ["ko", "MT4 다운로드"],
  ["ms", "Muat turun MT4"],
  ["pt", "Baixar MT4"],
  ["th", "ดาวน์โหลด MT4"],
  ["tl", "I-download ang MT4"],
  ["tr", "MT4'i indir"],
  ["vi", "Tải xuống MT4"],
  ["zh-HK", "下載 MT4"],
  ["zh-MY", "下载 MT4"],
]);
const platformMap: {
  [key: string]: {
    href: string;
    SVG: any;
    className?: string;
  };
} = {
  Windows: {
    href: "https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/mt4setup.exe",
    SVG: "static-windows",
    className: "accent-stroke-white",
  },
  Mac: {
    href: "https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.pkg.zip",
    SVG: "static-apple",
  },
  Linux: {
    href: "https://www.mql5.com/en/articles/1358",
    SVG: "static-linux",
  },
  iOS: {
    href: "https://apps.apple.com/us/app/metatrader-4/id496212596",
    SVG: "static-apple",
  },
  Android: {
    href: "https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4",
    SVG: "static-play-store",
  },
};

const AdaptiveMT4DownloadButton = ({
  data,
  debug,
  className,
  options = data?.fields?.options || [],
  ...props
}: {
  data: Entry<any>;
  debug?: any;
  className?: string;
  options?: {
    platforms: ("ios" | "android" | "desktop")[];
  };
}) => {
  const environment = usePlatformDetector();
  const platformData = platformMap[environment.platform ?? "Windows"];

  const router = useRouter();
  const { locale } = getRouteDetails(router, true);

  const downloadMT4Text = downloadMT4Map.has(locale as AlcatrazLocales)
    ? downloadMT4Map.get(locale as AlcatrazLocales)
    : downloadMT4Map.get("en");

  const ref = useRef(null);

  if (platformData) {
    return (
      <div
        {...props}
        className="relative mt-2 flex first:mt-0 md:mt-0 w-[160px] h-[49px]"
      >
        {debug}

        {environment.device === "Desktop" && options?.platforms?.includes("desktop") && (
          <Button
            title={downloadMT4Text}
            // TODO: Fix the type error
            icon={platformData.SVG}
            variant="secondary"
            url={platformData.href}
          />
        )}

        {environment.device === "Mobile" &&
          environment.platform === "iOS" &&
          options?.platforms?.includes("ios") && (
            <a
              className="inline-block w-fit h-full"
              href={platformData.href}
              ref={ref}
              {...props}
            >
              <Image
                alt="Apple App Store"
                loading="lazy"
                width="160"
                height="49"
                src="https://cdn.cmcmarkets.com/neptune/images/app-store-ios.svg"
              />
            </a>
          )}

        {environment.device === "Mobile" &&
          environment.platform === "Android" &&
          options?.platforms?.includes("android") && (
            <a
              className="inline-block w-fit h-full"
              href={platformData.href}
              ref={ref}
              {...props}
            >
              <Image
                alt="Google Play Store"
                loading="lazy"
                width="160"
                height="49"
                src="https://cdn.cmcmarkets.com/neptune/images/app-store-android.svg"
              />
            </a>
          )}
      </div>
    );
  }
};

export default AdaptiveMT4DownloadButton;
